import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const viewport = {
  content: "width=device-width, initial-scale=1.0, user-scalable=no"
}

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/workspace/forms'
    },
    {
      path: "/news",
      name: "news",
      component: () => import("@/views/workspace/news.vue"),
      meta: { title: '协同-消息', viewport: viewport }
    },
    {
      path: "/workspace",
      name: "workspace",
      component: () => import("@/views/workspace/WorkSpace.vue"),
      children: [
        {
          path: "forms",
          name: "forms",
          component: () => import("@/views/workspace/oa/FromsApp.vue"),
          meta: { title: '协同工作台', viewport: viewport },
        },
        {
          path: "submit",
          name: "submit",
          component: () => import("@/views/workspace/oa/MySubmit.vue"),
          meta: { title: '协同-我的请求', viewport: viewport },
        },
        {
          path: "cc",
          name: "cc",
          component: () => import("@/views/workspace/oa/CcMe.vue"),
          meta: { title: '协同-抄送我的', viewport: viewport },
        },
        {
          path: "unfinished",
          name: "unfinished",
          component: () => import("@/views/workspace/oa/UnFinished.vue"),
          meta: { title: '协同-待办事宜', viewport: viewport },
        },
        {
          path: "candidate",
          name: "candidate",
          component: () => import("@/views/workspace/oa/Candidate.vue"),
          meta: { title: '协同-认领事宜', viewport: viewport },
        },
        {
          path: "finished",
          name: "finished",
          component: () => import("@/views/workspace/oa/Finished.vue"),
          meta: { title: '协同-已办事宜', viewport: viewport },
        },
        {
          path: "details",
          name: "details",
          component: () => import("@/views/workspace/oa/details.vue"),
          meta: { title: '协同-详情', viewport: viewport },
        },
        {
          path: "instances",
          name: "instances",
          component: () => import("@/views/admin/ProcessInstanceManage.vue"),
          meta: { title: '协同-数据管理', viewport: viewport }
        },
        {
          path: "formsPanel",
          name: "formsPanel",
          component: () => import("@/views/admin/FormsPanel.vue"),
          meta: { title: '协同-表单列表', viewport: viewport }
        },
        {
          path: "subProcess",
          name: "subProcess",
          component: () => import("@/views/admin/SubProcess.vue"),
          meta: { title: '协同-子流程管理', viewport: viewport }
        },
      ]
    },
    {
      path: "/mbinitiate",
      name: "mbinitiate",
      component: () => import("@/views/workspace/MbInitiateProcess.vue"),
      meta: { title: '协同-发起审批', viewport: viewport, keepAlive: true }
    },
    {
      path: "/mbinitiateDefault",
      name: "mbinitiateDefault",
      component: () => import("@/views/workspace/MbInitiateProcessDefault.vue"),
      meta: { title: '协同-发起审批', viewport: viewport }
    },
    {
      path: "/selectTicket",
      name: "selectTicket",
      component: () => import("@/views/workspace/selectTicket.vue"),
      meta: { title: '协同-券品', viewport: viewport }
    },
    {
      path: "/tickRequestDetails",
      name: "tickRequestDetails",
      component: () => import("@/views/workspace/tickRequestDetails.vue"),
      meta: { title: '协同-券申请详情', viewport: viewport }
    },
    {
      path: "/tickRequest",
      name: "tickRequest",
      component: () => import("@/views/workspace/tickRequest.vue"),
      meta: { title: '协同-券申请', viewport: viewport }
    },
    //代理
    {
      path: "/approvalAgent",
      name: "approvalAgent",
      component: () => import("@/views/workspace/approvalAgent.vue"),
      meta: { title: '协同-设置代理人', viewport: viewport }
    },
    {
      path: "/mbInstance",
      name: "mbInstance",
      component: () => import("@/views/workspace/MbInstanceViewer.vue"),
      meta: { title: '协同-流程详情', viewport: viewport }
    },
    {
      path: "/admin/design",
      name: "design",
      component: () => import("@/views/admin/FormProcessDesign.vue"),
      meta: { title: '协同-表单流程设计', viewport: viewport }
    }, {
      path: "/admin/design/subProcess",
      name: "subProcess",
      component: () => import("@/views/admin/subProcess/SubProcessDesign.vue"),
      meta: { title: '协同-子流程设计', viewport: viewport }
    },
    {
      path: "/testForm",
      name: "testForm",
      component: () => import("@/views/common/form/TestForm.vue"),
      meta: { title: '协同-表单演示', viewport: viewport }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.content) {
    let head = document.getElementByTagName('head')
    let meta = document.createElemnet('meta')
    meta.name = 'viewport'
    meta.content = "width=device-width, initial-scale=1.0, user-scalable=no"
    head[0].appendChild(meta)
  }
  next();
  sessionStorage.setItem('router-path', to.path)
})

export default router;
